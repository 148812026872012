export const HOST =
  process.env.NODE_ENV === "production"
    ? window.location.host
    : "getreward.vip";

export function getHotGames(count, gameList, type) {
  if (type === "all") {
    return [...gameList].slice(0, count);
  } else {
    const sortList = [...gameList].sort(
      (a, b) => parseInt(b.playCounts) - parseInt(a.playCounts)
    );
    return sortList.slice(0, count);
  }
}

export function getRecommendGames(count, gameList) {
  let targetList = [...gameList].filter((game) => game.recommended > 0);
  if (targetList.length === 0) {
    const shuffledArray = [...gameList].sort(() => 0.5 - Math.random());
    targetList = shuffledArray.slice(0, 40);
  }
  return targetList.slice(0, count);
}

export function getHadBannerGames(count, gameList) {
  const targetList = [...gameList].filter((game) => game.banner.length > 0);
  const sortList = targetList.sort(
    (a, b) => parseInt(b.playCounts) - parseInt(a.playCounts)
  );
  return sortList.slice(0, count);
}

export function getAdContentHtml(type, adObj) {
  const { sdk_link, site_alias, content, script_content } = adObj;
  if (sdk_link) {
    const scriptSdk = document.createElement("script");
    scriptSdk.src = sdk_link;
    document.head.appendChild(scriptSdk);
  }

  if (type === "anchor") {
    var scriptContent = document.createElement("script");
    scriptContent.innerHTML = script_content;

    return `${content}${scriptContent.outerHTML}`;
  }
  if (type === "banner") {
    var bContent = document.createElement("script");
    bContent.innerHTML = script_content;

    return `${content}${bContent.outerHTML}`;
  }
  if (type === "interstitial") {
    var iContent = document.createElement("script");
    iContent.innerHTML = script_content;
    document.head.appendChild(iContent);
  }
  return "";
}
