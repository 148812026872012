import React from "react";
import "./index.css";
import NavBtn from "../NavBtn";

export default function Agreements() {
  return (
    <div className="w-full">
      <div className="px-[1rem] py-[1rem]">
        <NavBtn />
        <h1>Terms and Conditions</h1>
        <p>
          These terms and conditions ("Terms", "Agreement") are an agreement
          between Website Operator ("Website Operator", "us", "we" or "our") and
          you ("User", "you" or "your"). This Agreement sets forth the general
          terms and conditions of your use of the{" "}
          <a href="https://getreward.vip">https://getreward.vip</a> website
          and any of its products or services (collectively, "Website" or
          "Services").
        </p>

        <h2>Age Requirement</h2>
        <p>
          You must be at least 13 years of age to use this Website. By using
          this Website and by agreeing to this Agreement you warrant and
          represent that you are at least 13 years of age.
        </p>

        <h2>Backups</h2>
        <p>
          We are not responsible for Content residing on the Website. In no
          event shall we be held liable for any loss of any Content. It is your
          sole responsibility to maintain appropriate backup of your Content.
          Notwithstanding the foregoing, on some occasions and in certain
          circumstances, with absolutely no obligation, we may be able to
          restore some or all of your data that has been deleted as of a certain
          date and time when we may have backed up data for our own purposes. We
          make no guarantee that the data you need will be available.
        </p>

        <h2>General Use of the Service</h2>
        <ul>
          <li>You do not alter or modify any part of the Service.</li>
          <li>
            You agree not to use or launch any automated system, including
            without limitation, "robots," "spiders," or "offline readers," that
            accesses the Service in a manner that attempts to forge the presence
            of a user accessing the site for the purpose of artificially
            increasing "pageviews," "subscribers," or "likes" or other elements
            that contribute to a series popularity or advancement within any
            programs that getreward.vip offers to users.
          </li>
          <li>
            Notwithstanding the foregoing, getreward.vip grants the operators
            of public search engines permission to use spiders to copy Content
            from the site for the sole purpose of and solely to the extent
            necessary for creating publicly available searchable indices of the
            Content, but not caches or archives of such Content. Tapforgame.com
            reserves the right to revoke these exceptions either generally or in
            specific cases.
          </li>
          <li>
            You agree not to collect or harvest any personally identifiable
            information, including user account names, from the Service, nor to
            use the communication systems provided by the Service (e.g.,
            comments, posting) for any commercial solicitation purposes.
          </li>
          <li>
            In your use of the Service, you will comply with all applicable
            laws.
          </li>
          <li>
            getreward.vip reserves the right to discontinue any aspect of the
            Service at any time.
          </li>
        </ul>

        <h2>Advertisements</h2>
        <p>
          During use of the Website, you may enter into correspondence with or
          participate in promotions of advertisers or sponsors showing their
          goods or services through the Website. Any such activity, and any
          terms, conditions, warranties or representations associated with such
          activity, is solely between you and the applicable third-party. We
          shall have no liability, obligation or responsibility for any such
          correspondence, purchase or promotion between you and any such
          third-party.
        </p>

        <h2>Prohibited uses</h2>
        <p>
          In addition to other terms as set forth in the Agreement, you are
          prohibited from using the Website or its Content: (a) for any unlawful
          purpose; (b) to solicit others to perform or participate in any
          unlawful acts; (c) to violate any international, federal, provincial
          or state regulations, rules, laws, or local ordinances; (d) to
          infringe upon or violate our intellectual property rights or the
          intellectual property rights of others; (e) to harass, abuse, insult,
          harm, defame, slander, disparage, intimidate, or discriminate based on
          gender, sexual orientation, religion, ethnicity, race, age, national
          origin, or disability; (f) to submit false or misleading information;
          (g) to upload or transmit viruses or any other type of malicious code
          that will or may be used in any way that will affect the functionality
          or operation of the Service or of any related website, other websites,
          or the Internet; (h) to collect or track the personal information of
          others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape;
          (j) for any obscene or immoral purpose; or (k) to interfere with or
          circumvent the security features of the Service or any related
          website, other websites, or the Internet. We reserve the right to
          terminate your use of the Service or any related website for violating
          any of the prohibited uses.
        </p>

        <h2>Copyright Violations</h2>
        <p>
          If you are a copyright owner or an agent thereof and believe that any
          Content infringes upon your copyrights, you may submit a notification
          pursuant to the Digital Millennium Copyright Act ("DMCA") by providing
          our designated agent with the following information:
        </p>
        <ul>
          <li>
            A physical or electronic signature of a person authorized to act on
            behalf of the owner of an exclusive right that is allegedly
            infringed;
          </li>
          <li>
            Identification of the copyrighted work claimed to have been
            infringed, or, if multiple copyrighted works at a single online site
            are covered by a single notification, a representative list of such
            works at that site;
          </li>
          <li>
            Identification of the material that is claimed to be infringing or
            to be the subject of infringing activity and that is to be removed
            or access to which is to be disabled and information reasonably
            sufficient to permit the service provider to locate the material;
          </li>
          <li>
            Information reasonably sufficient to permit the service provider to
            contact you, such as an address, telephone number, and, if
            available, an email;
          </li>
          <li>
            A statement that you have a good faith belief that use of the
            material in the manner complained of is not authorized by the
            copyright owner, its agent, or the law; and
          </li>
          <li>
            A statement that the information in the notification is accurate,
            and under penalty of perjury, that you are authorized to act on
            behalf of the owner of an exclusive right that is allegedly
            infringed.
          </li>
        </ul>

        <h2>Limitation of Liability</h2>
        <p>
          To the fullest extent permitted by applicable law, in no event will
          Website Operator, its affiliates, officers, directors, employees,
          agents, suppliers or licensors be liable to any person for (a): any
          indirect, incidental, special, punitive, cover or consequential
          damages (including, without limitation, damages for lost profits,
          revenue, sales, goodwill, use or content, impact on business, business
          interruption, loss of anticipated savings, loss of business
          opportunity) however caused, under any theory of liability, including,
          without limitation, contract, tort, warranty, breach of statutory
          duty, negligence or otherwise, even if Website Operator has been
          advised as to the possibility of such damages or could have foreseen
          such damages. To the maximum extent permitted by applicable law, the
          aggregate liability of Website Operator and its affiliates, officers,
          employees, agents, suppliers and licensors, relating to the services
          will be limited to an amount greater of one dollar or any amounts
          actually paid in cash by you to Website Operator for the prior one
          month period prior to the first event or occurrence giving rise to
          such liability. The limitations and exclusions also apply if this
          remedy does not fully compensate you for any losses or fails of its
          essential purpose.
        </p>

        <h2>Changes and Amendments</h2>
        <p>
          We reserve the right to modify this Agreement or its policies relating
          to the Website or Services at any time, effective upon posting of an
          updated version of this Agreement on the Website. When we do, we will
          revise the updated date at the bottom of this page. Continued use of
          the Website after any such changes shall constitute your consent to
          such changes.
        </p>

        <h2>Acceptance of these terms</h2>
        <p>
          You acknowledge that you have read this Agreement and agree to all its
          terms and conditions. By using the Website or its Services you agree
          to be bound by this Agreement. If you do not agree to abide by the
          terms of this Agreement, you are not authorized to use or access the
          Website and its Services.
        </p>

        <h2>Contacting us</h2>
        <p>
          If you have any questions about this Agreement, please contact us.
        </p>
      </div>
    </div>
  );
}
