import React, { useMemo } from "react";
import HeaderBar from "../../components/Header";
import { Link, useLocation } from "react-router-dom";
import Footer from "../footer";
import { useAppSelector } from "../../store/hooks";
import { selectHadBannerGames } from "./homeSlice";
import MoreBtn from "../../components/MoreBtn";
import { HomeBottomBanner, HomeTopBanner } from "../../components/Ad";
import { HomeAnchor } from "../../components/Ad/Adhtml";

export default function Home() {
  const { search } = useLocation();
  const gameList = useAppSelector(selectHadBannerGames);

  const renderGameList = useMemo(() => {
    return gameList.map((item) => {
      return (
        <div
          key={item.id}
          className="w-full rounded-[0.5rem] h-[14.8rem] md:h-[17.6rem] my-[0.5rem] shadow-md overflow-hidden"
        >
          <Link to={`/detail/${item.id}${search}`}>
            <img
              className="w-full h-full object-cover"
              src={item.banner}
              alt={item.name}
            />
          </Link>
        </div>
      );
    });
  }, [gameList]);

  return (
    <div className="w-full max-w-[450px] min-w-[320px] mx-auto min-h-screen px-[0.75rem] py-[0.5rem]">
      {/* <HomeAnchor /> */}
      {/* <HomeTopBanner /> */}
      <HeaderBar />
      <div className="w-full mt-[0.5rem]">{renderGameList}</div>
      {/* <HomeBottomBanner /> */}
      <MoreBtn />
      <Footer />
    </div>
  );
}
