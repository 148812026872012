import React from "react";
import "./index.css";
import NavBtn from "../NavBtn";

export default function About() {
  return (
    <div className="w-full px-[1rem] py-[1rem]">
      <NavBtn />
      <div class="w-full pt-[1rem]">
        <h1 className="text-[2rem] font-bold mb-[0.5rem]">About Us</h1>
        <h2 className="text-[1.5rem] font-bold mb-[0.5rem]">
          getreward - Play Games
        </h2>
        <p>
          getreward is a browser game platform that features the best free online
          games. All of our games run in the browser and can be played instantly
          without downloads or installs. You can play on getreward on any device,
          including laptops, smartphones, and tablets. This means that whether
          you are at home, at school, or at work, it's easy and quick to start
          playing! Every month lots of people play our games.We publish new
          Html5 games every month and currently have more than 1.000 which you
          can freely play, without any download or installation.
        </p>
        <p>
          Our games are all free and unblocked, you won't have to pay a cent to
          play and have fun with them. They are also safe and secure, your
          device will be happy to surf through our website and explore its
          pages, its battery may be less content though ;) We feature over 500
          games in all genres that you can imagine.
        </p>
        <p>
          Should you have any feedback for us, please don't hesitate to reach
          out.
        </p>
        <p>Have fun on getreward.vip!</p>
        <p>Discover all games!</p>
      </div>
    </div>
  );
}
