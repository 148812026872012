import React from "react";
import { MyRouter } from "./Router";

function App() {
  return (
    <div className="w-full min-h-screen bg-gray-100">
      <MyRouter />
    </div>
  );
}

export default App;
